<template>
    <div class="engine-index-page">
        <div class="form-content">
            <language-selector ref="languageSelectorDom" :default="{source: form.originValue, target: form.targetValue}" :lang="autoLang" @change="languageSelectedChange"></language-selector>
            <div class="term-setting margin-l-15">
                <div class="setting-btn" @click="showTermSettingEvent">{{$t('engine.config_page_button_term_setting')}}</div>
                <div class="setting-switch margin-l-10">
                    <next-switch v-model="userEngineConfig.enabled" size="small" @change="setUserConfig"></next-switch>
                </div>
            </div>
        </div> 
        <div class="try-content">
            <div class="left-content">
                <div class="input-content" :lang="form.originValue">
                    <el-input 
                        type="textarea" 
                        resize="none" 
                        :placeholder="$t('engine.config_page_source_input_placeholder')" 
                        maxlength="2000" 
                        autofocus
                        @input="docValueChangeEvent"
                        v-model="form.docValue">
                    </el-input>
                    <div class="clear-btn" v-show="form.docValue.length>0" @click="clearDocValueEvent">
                        <svg-icon name="ib-close"></svg-icon>
                    </div>
                </div>
                <div class="action-content">
                    <div class="random-doc-btn" :disabled="randomDocBtnLoading" >
                        <template v-if="randomDocBtnShow">
                            <span @click="randomDocBtnEvent">
                                <svg-icon name="ib-refresh" :className="randomDocBtnLoading ? 'animation-spin' : ''"></svg-icon> {{form.docValue ? $t('engine.config_page_button_change') : $t('engine.config_page_button_try')}}
                            </span>
                        </template>
                    </div>
                    <div class="tag-content">
                        <span>{{randomSentenceLable}}</span>
                    </div>
                    <template v-if="form.docValue&&form.docValue.length">
                        <div class="word-number">{{form.docValue.length}}/2000</div>
                    </template>
                    <template v-else>
                        <div class="word-number cursor-pointer" v-permission="`engine:upload`" @click="goCreateProject"><svg-icon name="ib-upload"></svg-icon> {{$t('engine.config_page_button_upload')}}</div>
                    </template>
                </div>
            </div>
            <div class="center-content">
                <div class="field-type-list">
                    <template v-for="domain in domainAndEngineList">
                        <div :class="['item', domain.key===selectedDomain.key?'active':'']" 
                            :key="domain.key" 
                            @click="domainClickEvnet(domain)"
                            >{{domain.name}}</div>
                    </template>
                </div>
                <div class="engine-list" v-if="selectedDomain">
                    <template>
                        <template v-for="engine in engineList">
                            <div :class="['item', (selectedEngine && engine.key === selectedEngine.key)?'active':'']" 
                                :disabled="(history[engine.key] && history[engine.key].loading) || !engine.enabled"
                                :key="engine.key" 
                                @click="changeEngineEvent(engine)">
                                <div class="engine-icon">
                                    <i class="el-icon-loading" v-if="history[engine.key]&&history[engine.key].loading"></i>
                                    <svg-icon :name="'ie-' + engine.icon" v-else></svg-icon>
                                </div>
                                <div class="engine-name">{{engine.name}}</div>
                                <template v-if="selectedDomain.key === 'personal'">
                                    <el-tooltip :content="$t('engine.config_page_tooltip_engine_detail')" placement="top">
                                        <div class="engine-more" @click.stop="gotoDetailPage(engine)">
                                            <svg-icon name="ib-arrow-right"></svg-icon>
                                        </div>
                                    </el-tooltip>
                                </template>
                                <div class="triangle-mark" v-if="engine.proUsed"><span>PRO</span></div>
                                
                            </div>
                        </template>
                    </template>
                    <template v-if="selectedDomain.key === 'personal'">
                        <el-tooltip :content="$t('engine.config_page_tooltip_engine_create')" placement="top" v-permission="`engine:add`">
                            <div class="item add-engine" @click="gotoNewPage">
                                <svg-icon name="ib-plus"></svg-icon>
                            </div>
                        </el-tooltip>
                    </template>
                </div>
                <template v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) < 0">
                    <!-- <div class="fonts-12 margin-b-5" v-if="selectedDomain && selectedDomain.key === 'llm'">部分大模型翻译速度较慢，如需翻译文档，建议优先LanguageX和ChatGPT</div> -->
                    <div class="fonts-12" v-permission="`engine:add`" v-if="selectedDomain && (selectedDomain.key === 'general' || selectedDomain.key === 'llm')">{{$t('engine.config_page_message_1')}}<router-link to="/engine/ragCreate" target="_blank">{{$t('engine.config_page_message_2')}}</router-link></div>
                </template>
                <div class="fonts-12" v-if="selectedDomain&&selectedDomain.key === 'personal' && engineList.length==0">{{$t('engine.config_page_message_3')}}</div>
                <template v-if="userInfo.accountType === 'pro-limited' && selectedDomain&&selectedDomain.key === 'personal'">
                    <div class="fonts-12">{{$t('engine.config_page_message_4')}}<router-link to="/account/vip" target="_blank">{{$t('engine.config_page_message_5')}}</router-link></div>
                </template>
                <!-- <div class="fonts-12" v-if="selectedDomain&&selectedDomain.key === 'vertical'">垂直领域仅支持中英语言对</div> -->

            </div>
            <div class="right-content">
                 <template v-if="resultEditable">
                    <el-input v-model="form.resultValue" resize="none" type="textarea"></el-input>
                </template>
                <template v-else>
                    <div class="result-content" :lang="form.targetValue" v-html="form.resultHtml" @scroll="translationScoll($event)"></div>
                </template>
                <div class="action-content" v-if="form.resultValue">
                    <div class="copy-btn" @click="copyEvent({result: resultEditable ? form.resultValue : form.resultHtml})"><svg-icon name="ib-copy"></svg-icon> {{$t('engine.config_page_button_copy')}}</div>
                    <div class="flex flex-align-center fonts-12 margin-x-20"><span class="margin-r-5">{{$t('engine.config_page_button_edit')}}</span><next-switch v-model="resultEditable" size="small"></next-switch></div>
                </div>
            </div>
        </div>
        <div class="clear-all-content flex flex-align-center flex-justify-end" v-if="historyList.length>0">
            <div class="flex flex-align-center">
                <span class="margin-r-8 fonts-14">{{$t('engine.config_page_button_show_difference')}}</span><next-switch v-model="showDifferent" size="small"></next-switch>
            </div>
            <el-divider direction="vertical" class="margin-x-15" style="background-color:#ccc;"></el-divider>
            <el-button type="text" @click="clearHistroryBtnEvent"><svg-icon name="ib-trash-o"></svg-icon> {{$t('engine.config_page_button_clear_history')}}</el-button>
        </div>
        <div class="transition-history">
            <template v-for="(value, index) in historyList">
                <div class="item" :key="`result-${index}`">
                    <div class="title-content">
                        <div class="engine-name">
                            <span><svg-icon :name="'ie-' + value.icon" className="fonts-18"></svg-icon> {{value.name}}</span>
                        </div>
                        <div class="action-content">
                            <el-button round plain size="mini" class="action-btn" :class="{'active': value.polishResult && value.polishResult.length > 0}" :loading="value.polishLoading" @click="polishEvent(value)" v-if="!value.loading">
                                <svg-icon name="ib-brush" v-if="!value.polishLoading"></svg-icon> {{(value.polishResult && value.polishResult.length > 0) ? $t('engine.config_page_button_polish_again') : $t('engine.config_page_button_polish')}}
                            </el-button>
                            <el-button round plain size="mini" class="action-btn" @click="copyEvent(value)" v-if="!value.loading"><svg-icon name="ib-copy"></svg-icon> {{$t('engine.config_page_button_copy')}}</el-button>
                            <div class="close-btn" @click="deleteTransitionEvent(value.key)"><svg-icon name="ib-close"></svg-icon></div>
                        </div>
                    </div>
                    <div class="result-content" v-if="value.loading">
                        <p><i class="el-icon-loading"></i> {{$t('engine.config_page_message_6')}}</p>
                    </div>
                    <div v-else class="result-content" :lang="form.targetValue" @scroll="translationScoll($event)" 
                        v-html="value.polishResult ? value.polishHtml : (showDifferent ? value.different : value.html)"
                    ></div>
                </div>
            </template>
        </div>
        <term-setting ref="termSettingDom" :lang="{source: form.originValue|| 'zh', target: form.targetValue}"></term-setting>
    </div>
</template>
<script>
import $ from 'jquery';
import config from '@/utils/config';
import { mapState } from 'vuex';
import { projectApi, docApi, myEngineApi } from '@/utils/api';
import LanguageSelector from '@/components/LanguageSelector';
import TermSetting from './components/TermSetting';
import DiffMatchPatch from 'diff-match-patch';
import NextSwitch from '@/components/NextSwitch';

const dmp = new DiffMatchPatch(); //diff差异
dmp.Diff_EditCost = 5;

export default {
    components: { LanguageSelector, TermSetting, NextSwitch, },
    data(){
        return {
            autoLang: null,
            form:{
                originValue: '',
                targetValue: 'en',
                docValue: '',
                resultValue: '',
                resultHtml: '',
                resultDifferent: '',
            },
            resultEditable: false,
            showDifferent: true,
            domainAndEngineList:[],
            selectedDomain:null,
            engineList:[],
            selectedEngine:null,
            history:{},
            historyList:[],
            transitionTimer: null,
            myEngineTime: null,
            myEngineModelId: null,
            upgradePopoverVisible: false,

            // 随机句段
            randomDocBtnLoading: false,
            randomDocBtnShow: false,
            randomSentences: [],
            randomSentenceLable: '',

            //术语定制
            // termSwitch: false,
            userEngineConfig: {},
            userTermList: [],

            polishLimitCount: 3,
        }
    },
    computed:{
        ...mapState({
            engineConfig:state=>state.common.engineConfig,
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods:{
        gotoDetailPage(engine){
            let newpage = this.$router.resolve({path:'/engine/detail',query:{id:engine.modelId}})  
            window.open(newpage.href, '_blank');
        },
        gotoNewPage(){
            let newpage = this.$router.resolve({path:'/engine/ragCreate'})  
            window.open(newpage.href, '_blank');
        },
        goCreateProject(){
            if (this.userInfo.organizationType === 'ENTERPRISE') {
                this.$router.push('/project/create');
            }else {
                this.$router.push('/project/new');
            }
        },
        languageSelectedChange(val){
            // this.languageSelectorValue = val;
            console.log('xxxssssx:::',val);
            this.form.targetValue = val.target.key;
            this.form.originValue = val.source.key;
            this.historyList = [];
            this.history = {};
            this.autoLang = val.lang ? val.lang.key : null;
            if(val.auto && val.lang === null){
                this.detectionTextLanguage();
            }else{
                this.getEngineList();
            }
            
        },
        exchangeLanguage(){
            let tmpOriginValue = this.form.originValue;
            let tmpTargetValue = this.form.targetValue;
            this.form.originValue = tmpTargetValue;
            this.form.targetValue = tmpOriginValue;
            this.historyList = [];
            this.history = {};
            this.getEngineList();
        },
        clearDocValueEvent(){
            this.form.docValue = '';
            this.form.resultValue='';
            this.form.resultHtml = '';
            this.form.resultDifferent='';
            this.randomSentenceLable = '';
            this.historyList = [];
            this.history = {};
        },
        docValueChangeEvent(){
            let self = this;
            this.randomSentenceLable = '';
            if(this.transitionTimer){
                clearTimeout(this.transitionTimer);
            }
            this.transitionTimer = setTimeout(()=>{
                self.historyList = [];
                self.history = {};
                self.detectionTextLanguage();
            },500)
        },
        /**
         * 获取文本的语言方向
         */
        detectionTextLanguage(){
            let langVal = this.$refs.languageSelectorDom.getObjectValues();
            // console.log('xxx::::',langVal);
            if(!langVal.auto){
                this.getEngineList();
                return;
            }
            if(!this.form.docValue){
                this.form.resultValue = '';
                this.form.resultHtml = '';
                this.form.resultDifferent = '';
                return;
            }
            let url = docApi.getTextLanguage;
            let postData = {
                text: this.form.docValue,
            };
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200){
                    if(this.form.originValue === res.data) {
                        this.transitionRandomDoc();
                        return;
                    }
                    if (res.data !== 'unknown'){
                        this.form.originValue = res.data;
                        this.autoLang = res.data;
                    }else{
                        this.form.originValue = 'zh';
                        this.autoLang = 'zh';
                    }
                    if (this.form.originValue === this.form.targetValue){
                        if (this.form.originValue === 'zh'){
                            this.form.targetValue = 'en';
                        }else{
                            this.form.targetValue = 'zh';
                        }
                    }
                    this.getEngineList();
                }
            });
        },
        domainClickEvnet(domain){
            this.selectedDomain = domain;
            this.engineList = domain.engines;
        
            this.setSelectedEngine(domain);

            this.transitionRandomDoc();
            this.getRandomSentences();
            
        },
        /**
         * 设置默认选择的引擎
         */
        setSelectedEngine(domain){
            if (domain.engines.length > 0) {
				if (this.selectedEngine) {
					let has = domain.engines.filter((item) => item.key === this.selectedEngine.key);
					if (has.length === 0) {
                        this.selectedEngine = null;
                        for(let engine of domain.engines){
                            if(engine.enabled && !engine.proUsed){
                                this.selectedEngine = engine;
                                break;
                            }
                        }
					}
				} else {
                    this.selectedEngine = null;
                    for(let engine of domain.engines){
                        if(engine.enabled && !engine.proUsed){
                            this.selectedEngine = engine;
                            break;
                        }
                    }
				}
            }else{
                this.selectedEngine = null;
            }
        },
        changeEngineEvent(engine){
            if(!engine.enabled){
                this.$message.error(this.$t('engine.config_page_message_7'));
                return false;
            }
            if (engine.proUsed){
                this.$message({
                    type: 'warning',
                    dangerouslyUseHTMLString: true,
                    message: `${this.$t('engine.config_page_message_8')}<a href="/account/vip">${this.$t('engine.config_page_message_9')}</a>`,
                });
                return false;
            }
            this.selectedEngine = engine;
            this.transitionRandomDoc();
            if(engine.modelId || this.selectedDomain.key === 'vertical'){
                this.getRandomSentences();
            }
            if('deep-seek'=== engine.key){
                this.$message.warning(this.$t('engine.config_page_message_18'));
            } 
        },
        getEngineList(){
            let url =`${projectApi.queryEnginesByLang}?sourceLang=${this.form.originValue || 'zh'}&targetLang=${this.form.targetValue}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.domainAndEngineList = res.data;
                    if(this.selectedDomain){
                        let key = this.selectedDomain.key;
                        let tmpDomain = res.data.filter(item=>item.key === key&&item)[0];
                        this.selectedDomain = tmpDomain;
                    }else{
                        this.selectedDomain = res.data[0];
                    }
                    this.engineList = this.selectedDomain.engines;

                    this.setSelectedEngine(this.selectedDomain);
                    
                    this.transitionRandomDoc();
                    this.getRandomSentences();
                }
            })
        },
        async transitionRandomDoc(){
            // console.log('xxxx:::',this.selectedEngine, this.form)
            if(!this.form.docValue){
                this.form.resultValue = '';
                this.form.resultHtml = '';
                this.form.resultDifferent = '';
                return;
            }
            if(!this.selectedEngine){
                return;
            }
            let termList = [];
            if(this.userEngineConfig.enabled){
                let url = `${myEngineApi.queryMyTerms}?sourceLanguage=${this.form.originValue}&targetLanguage=${this.form.targetValue}`;
                let res = await this.$ajax.get(url);
                termList = res.data.terms;
            }
            this.userTermList = termList;
            let url = docApi.translationRandomText;
            let postData = {
                domain:this.selectedEngine.domain,
                engine:this.selectedEngine.key.split('@')[0],
                engineKey: this.selectedEngine.key,
                // ...this.selectedEngine,
                sourceLang:this.form.originValue,
                sourceText:this.form.docValue,
                targetLang:this.form.targetValue,
                terms: termList,
            };
            if(this.selectedEngine.modelId){
                postData.modelId = this.selectedEngine.modelId;
            }
            
            this.history[this.selectedEngine.key] = {...this.selectedEngine, loading: true, result:'', html:'', different:'', polishLoading: false, polishResult: '', polishHtml: '', polishCount: 0, };
            this.setHistoryList();
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    /* if(this.selectedEngine.key === 'chatgpt'){
                        if(!res.data.target){
                            this.$message.warning('ChatGPT目前官方服务不稳定，请稍后重试或使用文件翻译');
                        }
                    } */
                    let target = res.data.target || '';
                    let html = '<p>'+ target.replace(/<span class=\"term-highlight\"( [^>]*)?>/g, "").replace(/<\/span>/g, "").replaceAll('<', '&lt;').replaceAll('>', '&gt;').replace(/\n/g, '\n</p><p>') +'</p>';
                    this.history[res.data.engineKey].result = target;
                    this.history[res.data.engineKey].html = html;
                    // this.history[res.data.engineKey].different = '';
                    this.history[res.data.engineKey].loading = false;
                    this.form.resultValue = $(`<span>${target}</span>`).text();
                    // this.form.resultDifferent = different;
                    this.form.resultHtml = html;
                }
            }).finally(()=>{
                this.history[this.selectedEngine.key].loading = false;
                this.setHistoryList();
                this.getTargetCompareResult();
            });
        },
        getTargetCompareResult(){
            let url = myEngineApi.queryTargetTextHighlight;
            let keys = Object.keys(this.history);
            let tarTexts = {};
            keys.map(key => {
                tarTexts[key] = this.history[key].result;
            });
            let postData = {
                tarTexts,
                srcText: this.form.docValue,
                srcLang: this.form.originValue,
                tarLang: this.form.targetValue,
                tag: 'span',
                elementParams: 'class="diff-highlight"',
            }
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200){
                    let data =  res.data;
                    let dKeys = Object.keys(data);
                    dKeys.map(key => {
                        this.history[key].different = '<p>'+data[key].replace(/\n/g,'</p><p>')+'</p>';
                    });
                }
            }).finally(() => {
                this.setHistoryList();
            });
        },
        deleteTransitionEvent(key){
            this.$delete(this.history, key);
            this.setHistoryList();
        },
        polishEvent(item) {
            if (item.polishCount >= this.polishLimitCount) {
                this.$message.warning(this.$t('engine.config_page_message_17', {count: this.polishLimitCount}));
                return;
            }
            item.polishLoading = true;
            let url = docApi.polishText;
            let postData = {
                sourceLang: this.form.originValue,
                targetLang: this.form.targetValue,
                sourceText: item.result,
            }
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200) {
                    let count = item.polishCount + 1;
                    item.polishCount = count;
                    item.polishResult = res.data;
                    item.polishHtml = '<p>'+res.data.replaceAll('<', '&lt;').replaceAll('>', '&gt;').replace(/\n/g,'</p><p>')+'</p>';
                    this.history[item.key].polishCount = count;
                    this.history[item.key].polishResult = item.polishResult;
                    this.history[item.key].polishHtml = item.polishHtml;
                }
            }).finally(() => {
                item.polishLoading = false;
            })
        },
        copyEvent(item){
            let self = this;
            let _text = $(`<span>${item.polishResult ? item.polishResult : item.result}</span>`).text();
            this.$copyText(_text).then(function (e) {
                self.$message.success(self.$t('engine.config_page_message_10'));
            }, function (e) {
                self.$message.error(self.$t('engine.config_page_message_11'));
            })
        },
        setHistoryList(){
            let list = [];
            let keys = Object.keys(this.history);
            keys.map(key =>{
                list.push({
                    ...this.history[key],
                    key: key,
                })
            });
            this.historyList = list.reverse();
            // console.log(this.historyList,this.history);
        },
        clearHistroryBtnEvent(){
            let keys = Object.keys(this.history);
            keys.map(key=>{
                this.$delete(this.history, key);
            });
            this.setHistoryList();
        },
        startPollingCustomEngineStatus(){
            let self = this;
            // let hasMyEngine = this.engineList.filter(item=>item.key === 'robot');
            clearInterval(this.myEngineTime);
            if(this.selectedDomain.key === 'personal'){
                self.pollingCustomEngineStatus();
                this.myEngineTime = setInterval(()=>{
                    self.pollingCustomEngineStatus();
                },1000);
            }
        },
        pollingCustomEngineStatus(){
            let url = `${projectApi.pollingCustomEngineStatus}?sourceLang=${this.form.originValue}&targetLang=${this.form.targetValue}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let modules= res.data;
                    let tmpList = this.engineList;
                    this.engineList = tmpList.map(item=>{
                        if(!item.canUsed && modules.indexOf(item.modelId)>-1){
                            return {
                                ...item,
                                canUsed: true,
                            }
                        }else{
                            return item;
                        }
                    });
                    let tmpStopList = this.engineList.filter(item=> item.canUsed === false);
                    if(tmpStopList.length === 0){
                        clearInterval(this.myEngineTime);
                    }
                }
            });
        },
        upgradeBtnEvent(){
            this.upgradePopoverVisible = false;
            this.$router.push('/account/vip');
            // this.$store.dispatch('pay/setPayDialogAction',{show:true});
        },
        getRandomSentences(){
            let langVal = this.$refs.languageSelectorDom.getObjectValues();
            this.randomSentences = [];
            this.randomDocBtnShow = false;
            if(!((langVal.auto && langVal.lang) || (!langVal.auto && this.form.originValue))){
                return;
            }
            if(!this.selectedEngine){
                return;
            }
            let url = docApi.queryRandomParagraphList;
            let postData = {
                source: this.form.originValue,
                domain: this.selectedEngine.domain,
            }
            if (this.selectedEngine.modelId) {
				postData.modelId = this.selectedEngine.modelId;
			}
            this.$ajax.post(url, postData).then(res => {
                if(res.status == 200){
                    this.randomSentences = res.data || [];
                    this.randomDocBtnShow = this.randomSentences.length > 0;
                }
            })
        },
        randomDocBtnEvent(){
            this.randomDocBtnLoading = true;
            let index = Math.floor(Math.random() * this.randomSentences.length);
            this.form.docValue = this.randomSentences[index].sourceText;
            this.randomSentenceLable = this.randomSentences[index].tags;
            this.historyList = [];
            this.history = {};
            this.$nextTick(() => {
                this.randomDocBtnLoading = false;
                // this.getEngineTransition();
                this.transitionRandomDoc()
            });
        },
        showTermSettingEvent(){
            if(!this.form.originValue){
                this.$message.error(this.$t('engine.config_page_message_12'));
                return;
            }
            this.$refs.termSettingDom.setShow({source: this.form.originValue, target: this.form.targetValue,}, true);
        },
        getUserConfig(){
            let url = myEngineApi.queryUserConfig;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.userEngineConfig = res.data.termCustom;
                }
            })
        },
        setUserConfig(){
            let url = myEngineApi.updateTermSwitch;
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    if(this.userEngineConfig.enabled){
                        this.$message.success(this.$t('engine.config_page_message_13'));
                    }else{
                        this.$message.success(this.$t('engine.config_page_message_14'));
                    }
                    console.info('[INFO]', '术语定制开关已更新');
                }
            })
        },
        initTermTips(){
            let self = this;
            $('.engine-index-page').delegate('.term-highlight', 'click', function(e){
                $('.term-tips').remove();
                let id = $(this).data('id');
                let list = self.userTermList.filter(item => item.termId === id);
                if (list.length === 0){
                    self.$message.error(this.$t('engine.config_page_message_15'));
                    return;
                }
                let mainScrollTop = $('.el-main').scrollTop();
                let offset = $(this).offset();
                let left = offset.left - 144;
                let top = offset.top + mainScrollTop - 23; // - 58 - 14
                
                let html = `<div class="term-tips" style="left:${left}px;top:-100px;">${this.$t('engine.config_page_message_16')}${list[0].sourceText}</div>`;
                $('.engine-index-page').append(html);
                setTimeout(function(){
                    let owenHeight = $('.term-tips').outerHeight();
                    console.log('owenHeight::', owenHeight);
                    $('.term-tips').css({top: (top - owenHeight - 6 - 22)+'px'});
                }, 10);
                
            });
            $(document).click(function(e){
                if(!$(e.target).hasClass("term-highlight")){
                    $('.term-tips').remove();
                }
            });
        },
        translationScoll(event){
            $('.term-tips').remove();
        }
    },
    /* beforeRouteLeave: function(to, from , next){
        if(this.form.docValue){
            this.$confirm('确定要离开吗？离开会清空本页内容', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                next();
            })
        }else{
            next();
        }
    }, */
    created(){
        $('.el-main').addClass('engine-config-bg');
        // console.log('$txxxx::', this.$i18n.locale)
        this.getUserConfig();
        let query = this.$route.query;
        if(query.source){
            this.form.originValue = query.source;
        }
        if(query.target){
            this.form.targetValue = query.target;
        }
    },
    mounted(){
        let self = this;
        this.getEngineList();
        // this.initTermTips();
        $('body').delegate('.pro-engine-upgrade','click',function(e){
            self.$router.push('/account/vip');
        });
    },
    beforeDestroy(){
        $('.el-main').removeClass('engine-config-bg');
        clearInterval(this.myEngineTime);
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.engine-index-page{
    padding-top: 20px;
    position: relative;
    .form-content{
        display: flex;
        justify-content: center;
        align-items: center;
        .transition-icon{
            width: 64px;
            cursor: pointer;
            text-align: center;
            & > svg{
                font-size: 18px;
            }
            &[disabled]{
                cursor: not-allowed;
                pointer-events: none;
                opacity: .5;
            }
        }
    }
    .term-setting{
        display: flex;
        align-items: center;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid #e5e5e5;
        background-color: #FFFFFF;
        border-radius: 4px;
        padding: 0 15px; 
        font-size: 14px;
        .setting-btn{
            cursor: pointer;
            color: $a-color;
        }
        &:hover{
            border-color: #000000;
        }
    }
    .try-content{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .left-content{
            flex: 1;
            display: flex;
            flex-direction: column;
            max-width: 400px;
            min-width: 250px;
            min-height: 304px;
            background: #FFFFFFB3;
            border: 1px solid #fff;
            box-sizing: border-box;
            border-radius: 8px;
            & > .input-content{
                flex: 1;
                position: relative;
                .el-textarea{
                    height: 100%;
                }
                > .clear-btn{
                    font-size: 14px;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    top: 0;
                    right: 2px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: #999;
                    &:hover{
                        color: #000;
                    }
                }
            }
            & > .action-content{
                display: flex;
                margin: 20px;
                font-size: 12px;
                > .random-doc-btn{
                    text-align:left; 
                    cursor: pointer;
                    color: $a-color;
                    > svg{
                        margin-right: 10px;
                        font-size: 12px;
                    }
                }
                > .tag-content {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    color: #666;
                }
                > .word-number{
                    // width: 50px;
                    text-align: right
                }
            }
        }
        .center-content{
            width: 430px;
            margin-left: 40px;
            margin-right: 40px;
            > .field-type-list{
                display: flex;
                border-bottom: 1px solid #0000000D;
                > .item {
                    flex: 1;
                    font-size: 16px;
                    color: #00000066;
                    text-align: center;
                    margin-bottom: 20px;
                    cursor: pointer;
                    
                    &.active{
                        color: #000000;
                        font-weight: 500;
                    }
                }
            }
            > .engine-list{
                margin: 20px 0;
                display: grid;
                grid-gap: 20px;
                grid-template-columns: repeat(auto-fill,minmax(130px, 1fr));
                .item{
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    width: 130px;
                    height: 44px;
                    background-color: #FFFFFF;
                    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
                    box-sizing: border-box;
                    border: 1px solid #ffffff;
                    border-radius: 6px;
                    
                    &.quick-create {
                        background-image: url("../../../static/images/engine/quick-create-engine-flag.png");
                        background-position: top right;
                        background-repeat: no-repeat;
                        background-size: 24px 24px;
                    }
                    &.active{
                        border-color: #000000;
                        // color: #21B780; 
                    }
                    &[disabled],&:disabled{
                        cursor: not-allowed;
                        // pointer-events: none;
                        opacity: .5;
                    }
                    &.add-engine{
                        justify-content: center;
                        > svg{
                            font-size:20px;
                        }
                    }
                    > .engine-icon{
                        margin-left:8px;
                        font-size: 22px;
                    }
                    > .engine-name{
                        font-size: 13px;
                        line-height: 24px;
                        flex: 1;
                        margin-left: 5px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    > .engine-more{
                        width: 20px;
                        height: 100%;
                        line-height: 44px;
                        text-align: center;
                        border-radius: 0 6px 6px 0;
                        &:hover{
                            background-color: #EDEDEDB3;
                        }
                        > svg{
                            font-size:14px;
                        }
                    }
                    
                }
            }
        }
        .right-content{
            flex: 1;
            max-width: 400px;
            min-width: 250px;
            // height: 100%;
            background: #FFFFFFB3;
            border: 1px solid #fff;
            box-sizing: border-box;
            border-radius: 8px;
            color: #000000;
            display: flex;
            flex-direction: column;
            .result-content{
                flex: 1;
                // max-height: 400px;
                padding: 20px;
                overflow-y: auto;
                position: relative;
                ::v-deep p{
                    line-height: 22px;
                    font-size: 14px;
                }
                ::v-deep .term-highlight{
                    color: #00B16C;
                    // cursor: pointer;
                }
            }
            .action-content{
                height: 48px;
                display: flex;
                // justify-content: flex-end;
                align-items: center;
                justify-content: space-between;
                user-select: none;
                > .copy-btn{
                    width: 60px;
                    height: 28px;
                    margin-left: 20px;
                    line-height: 28px;
                    text-align: center;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    box-sizing: border-box;
                    border-radius: 32px;
                    font-size: 12px;
                    cursor: pointer;
                }
            }
            ::v-deep .el-textarea {
                flex: 1;
                .el-textarea__inner {
                    height: 100%;
                }
            }
            ::v-deep .el-textarea__inner {
                height: 100%;
            }
        }
    }
    .clear-all-content{
        margin: auto;
        width: 100%;
        max-width: 1257px;
        text-align: right;
        font-size: 12px;
    }
    .transition-history{
        // margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        > .item{
            width: 100%;
            max-width: 1310px;
            background-color: #FFFFFFB3;
            border: 1px solid #0000001A;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 10px 20px;
            margin-bottom: 10px;
            > .title-content{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
                > .engine-name{
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    
                }
                > .action-content{
                    display: flex;
                    align-items: center;
                    .action-btn {
                        background: transparent;
                        border-color: rgba(0, 0, 0, 0.15);
                        color: #000;
                        &.active {
                            border-color:#00B16C;
                            color: #00B16C;
                        }
                    }
                    > .close-btn{
                        cursor: pointer;
                        margin-left: 10px;
                        font-size: 14px;
                        color: #999;
                        &:hover{
                            color: #000;
                        }
                    }
                }
            }
            > .result-content{
                max-height: 78px;
                overflow-y: auto;
                ::v-deep p{
                    line-height: 20px;
                    font-size: 14px;
                }
                ::v-deep .term-highlight{
                    color: #00B16C;
                    cursor: pointer;
                }
                ::v-deep .diff-highlight{
                    // color: #E98C02;
                    background-color: #D4E4FF;
                }
            }
        }
    }
    ::v-deep .term-tips{
        position: absolute;
        padding: 10px;
        background-color: #000000CC;
        border-radius: 6px;
        box-sizing: border-box;
        color: #fff;
        font-size: 12px;
        max-width: 380px;
        line-height: 1.2;
        &::after{
            content: "";
            display: block;
            position: absolute;
            bottom: -6px;
            left: 15px;
            border-left: 6px solid transparent ;
            border-right: 6px solid transparent;
            border-top: 6px solid #000000CC;
        }
    }
}

</style>
<style lang="scss">
.try-content{
    .el-textarea__inner{
        height: 100%;
        border:none;
        background-color: inherit;
        padding: 20px;
        font-size: 14px;
        color: #000000;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
    }
    p{
        line-height: 22px;
        &:empty{
            height: 22px;
        }
    }   
}
.loading-style{
    border-radius: 8px;
}
</style>
